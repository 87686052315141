import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import SignPdfModal from '../components/sign-pdf-modal';
import Button from '../components/button';

import {
  selectAndCheckIfAgreementSignedForCurrentPlanInTheCart,
  selectBillingAddresses,
  selectCart,
  selectCoinPaymentDiscount,
  selectConditionToShowPurchaseOrSignButton,
  selectIbpProduct,
  selectIfIbpInTheCart,
  selectPaymentCardForCvvConfirm,
  selectPaymentsWithDefault,
  selectPreAuthorizeModalCondition,
  selectShowVatAmount,
  showAutoRenewalMessage,
} from '../store/selectors';
import { selectLoader, selectModal } from '../store/selectors/global';
import {
  selectAuditTrial,
  selectCalculatedPrice,
  selectElectronicSignments,
  selectEntityBillingDetails,
  selectEntityUserDetails,
} from '../store/selectors/entities';
import { downloadFile, isClient, SIGNING_AUDIT_TRAIL_TYPE } from '../helpers/utils';
import showPdfAgreement from '../components/checkout-total/showPdfAgreement';
import { ButtonLoaderTypes } from '../constants/types';
import { addToCart, setAuditTrailStatus } from '../store/actions';
import styles from '../styles/pages/signagreement.module.scss';

const strings = {
  pleaseStarting: 'Please starting signing an agreement by clicking on Sign agreement button below',
  signAgreement: 'Sign agreement',
  youHaveSuc:
    'You have successfully signed your publisher agreement document! you should also receive a copy of the sign agreement document and alternatively can review or download the document here below',
};

const stateSelector = createStructuredSelector({
  cartData: selectCart,
  billingAddresses: selectBillingAddresses,
  entityBillingDetails: selectEntityBillingDetails,
  modal: selectModal,
  userDetails: selectEntityUserDetails,
  showVat: selectShowVatAmount,
  savedMethods: selectPaymentsWithDefault,
  showAutoRenewal: showAutoRenewalMessage,
  preAuthorizeCondition: selectPreAuthorizeModalCondition,
  electronicSignments: selectElectronicSignments,
  isAgreementSignedForCurrentCart: selectAndCheckIfAgreementSignedForCurrentPlanInTheCart,
  showPurchaseOrSign: selectConditionToShowPurchaseOrSignButton,
  cvvCardForConfirmation: selectPaymentCardForCvvConfirm,
  coinPaymentDiscount: selectCoinPaymentDiscount,
  calculatedPrice: selectCalculatedPrice,
  auditTrial: selectAuditTrial,
  ibpProduct: selectIbpProduct,
  isIbpInTheCart: selectIfIbpInTheCart,
  isLoading: selectLoader,
});

const SignAgreementPage = () => {
  const dispatch = useDispatch();

  const {
    cartData,
    billingAddresses,
    userDetails,
    electronicSignments,
    auditTrial,
    ibpProduct,
    isIbpInTheCart,
    isLoading,
  } = useSelector(stateSelector);
  const [isSigned, setIsSigned] = useState(false);
  const [showSignPdf, toggleSignPdf] = useState(false);
  const hasElectronicSignments = electronicSignments?.length;
  const electronicSignmentUrl = hasElectronicSignments ? electronicSignments[0].url : '';

  const setIbpProductToCart = () => dispatch(addToCart('product', ibpProduct));

  useEffect(() => {
    if (!isIbpInTheCart && ibpProduct) {
      setIbpProductToCart();
    }
  }, [ibpProduct]);

  const showPdf = (action) => {
    if (!isClient) {
      return;
    }

    try {
      // eslint-disable-next-line consistent-return
      return showPdfAgreement(
        action,
        billingAddresses?.find((address) => address.primary),
        cartData,
        userDetails
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error while showing PDF Document');
    }
  };

  const onSubmit = () => {
    setIsSigned(true);
  }

  const changeAuditTrailStatus = (step, externalId) => {
    dispatch(setAuditTrailStatus({ step, externalId }));
  };

  const showPdfModal = () => {
    changeAuditTrailStatus(SIGNING_AUDIT_TRAIL_TYPE.INITIATED.step);
    toggleSignPdf(true);
  };

  const onDownloadFile = () => downloadFile(electronicSignmentUrl, `ibi_agreement_document.pdf`);

  return (
    <Layout hideNav>
      <Seo title="Sign Agreement" />
      <div className={styles.content}>
        {!isSigned && !hasElectronicSignments && (
          <>
            <h3>{strings.pleaseStarting}</h3>
            <div className={styles.signButtonContainer}>
              <Button
                defaultWidth
                size="large"
                className={styles.signButton}
                onClick={showPdfModal}
                loaderType={ButtonLoaderTypes.PURCHASE}
                disabled={isLoading}
              >
                {strings.signAgreement}
              </Button>
            </div>
          </>
        )}

        {!isSigned && hasElectronicSignments && (
          <>
            <div className={styles.hasSigned}>{strings.youHaveSuc}</div>
            <div className={styles.signButtonContainer}>
              <Button
                defaultWidth
                size="large"
                onClick={onDownloadFile}
                disabled={isLoading}
              >
                Download
              </Button>
            </div>
          </>
        )}

        {isSigned && (
          <div className={styles.successfullySigned}>
            <p>
              You have successfully sign the agreement. You can return to{' '}
              <a href="https://www.bebackoffice.com/">Bebackoffice</a>
            </p>
          </div>
        )}
      </div>

      <SignPdfModal
        isModal
        show={showSignPdf}
        toggle={toggleSignPdf}
        onSubmit={onSubmit}
        userDetails={userDetails}
        cartData={cartData}
        electronicSignments={electronicSignments}
        showPdf={() => showPdf('getBlob')}
        billingAddresses={billingAddresses}
        setAuditTrialStatus={changeAuditTrailStatus}
        externalId={auditTrial.id}
        payBtnText="Submit"
      />
    </Layout>
  );
};

export default SignAgreementPage;
